<template>
    <v-container fluid>
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-card-text class="p-2">
                        <v-container fluid>
                            <v-row>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="dept_id"
                                        :items="regions"
                                        item-value="dept_id"
                                        item-text="descr"
                                        @change="getOffice"
                                        label="Region"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-office-building"
                                        clearable
                                        dense
                                        solo
                                        v-model="office_id"
                                        :items="offices"
                                        item-value="office_id"
                                        item-text="office"
                                        label="Office"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal"
                                        v-model="modal"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_from"
                                            label="Start Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="date"
                                            v-model="date_from"
                                            no-title
                                            @input="modal = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-menu
                                        ref="modal_to"
                                        v-model="modal_to"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            solo
                                            dense
                                            v-model="date_to"
                                            label="End Period"
                                            persistent-hint
                                            prepend-inner-icon="mdi-calendar"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="ma-0 pa-0 border-12"
                                            hide-details=true
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            type="date"
                                            v-model="date_to"
                                            no-title
                                            @input="modal_to = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-shape"
                                        clearable
                                        dense
                                        solo
                                        v-model="cat_id"
                                        :items="categories"
                                        item-value="cat_id"
                                        item-text="descr"
                                        label="Category"
                                        @change="getCommodity"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-shape"
                                        clearable
                                        dense
                                        solo
                                        v-model="com_id"
                                        :items="commodities"
                                        item-value="com_id"
                                        item-text="com_desc"
                                        label="Commodity"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-store"
                                        clearable
                                        dense
                                        solo
                                        v-model="dept_id"
                                        :items="departments"
                                        item-value="dept_id"
                                        item-text="dept_name"
                                        label="Department"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <!-- <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-flag"
                                        clearable
                                        dense
                                        solo
                                        v-model="raw"
                                        :items="raws"
                                        item-value="value"
                                        :item-text="item => item.raw"
                                        label="Material Flag"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col> -->
                                <v-col class="col-12" sm="6" md="2" lg="2">
                                    <v-autocomplete
                                        prepend-inner-icon="mdi-list-status"
                                        clearable
                                        dense
                                        solo
                                        v-model="status"
                                        :items="statuses"
                                        item-value="value"
                                        :item-text="item => item.status"
                                        label="Status"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>
                                </v-col>
                                <v-col class="col-12" sm="6" md="2">
                                    <v-btn class="border-12 mr-2"  style="padding: 20px;"
                                    elevation="2" small @click="clear()">Clear</v-btn>
                                    <v-btn class="border-12" style="padding: 20px;"
                                    color="info" elevation="2" small @click="searchFunction()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-2 mb-12">
            <v-col cols="12">
                <h6 class="indigo-text m-2">Query Result: </h6>
            </v-col>
            <v-col class="col-12" sm="6" md="4">
                <v-card outlined class="p-0" height="500">
                    <v-card-text>
                        <v-img
                        class="text-center mt-12 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 475px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="6" md="8">
                <v-card outlined class="p-0" height="500">
                    <v-card-text class="p-1">
                        <v-data-table
                            dense
                            :headers="headers"
                            :items="items"
                            class="elevation-1"
                            :items-per-page="30"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            :loading="$store.state.overlay"
                            height="350"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :search="searchItemTrans" 
                            :item-class="tr_datatable"
                            group-by="pr_id"
                        >    
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                    <div class="d-flex w-100">
                                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> List Item </h6>
                                        <v-spacer></v-spacer>
                                        <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchItemTrans" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        <v-btn
                                        small
                                        color="#005c37"
                                        class="py-5 mr-3 text-white border-12"
                                        >
                                            <v-icon>mdi-file-excel</v-icon>
                                            <download-excel
                                                class="text"
                                                :fetch           = "exportExcel"
                                                :data="pr_items"
                                                :before-generate = "startDownload"
                                                :before-finish   = "finishDownload">
                                                Export Excel
                                            </download-excel>
                                        </v-btn>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.dt_pr`]="{ item }">
                                {{ (new Date(new Date(item.dt_pr) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                            </template>
                            <!-- <template v-slot:[`item.dt_aprv1`]="{ item }">
                                {{ item.dt_aprv1 === '1900-01-01 00:00:00.000' ? '-' : item.dt_aprv1}}
                            </template>
                            <template v-slot:[`item.dt_aprv2`]="{ item }">
                                {{ item.dt_aprv2 === '1900-01-01 00:00:00.000' ? '-' : item.dt_aprv2}}
                            </template> -->
                            <template v-slot:[`item.qty`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.qty)}}
                            </template>
                            <template v-slot:[`item.qty_po`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.qty_po)}}
                            </template>
                            <template v-slot:[`item.qty_rcv`]="{ item }">
                                {{$store.getters.convertToCurrencyUs(item.qty_rcv)}}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {filter} from "@/backend-api/filter/index"
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Kencana Retail Indonesia',
                    disabled: false,
                    href: '/admin/kri',
                },
                {
                    text: 'Finance Report',
                    disabled: true,
                },
                {
                    text: 'PR Detail',
                    disabled: true,
                }
            ],
            search:'',
            modal: false,
            date_from: '',
            modal_to: false,
            date_to: '',
            dept_id: '',
            office_id: '',
            cat_id: '',
            com_id: '',
            departments: [],
            regions: [],
            offices: [],
            categories: [],
            commodities: [],
            department: '',
            // raws: [
            //     {
            //         raw: 'Raw Material Yes',
            //         value: 'Y'
            //     },
            //     {
            //         raw: 'Raw Material No',
            //         value: 'N'
            //     }
            // ],
            raw: 'N',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            showData: true,
            headers: [
                { text: 'Date PR', value: 'dt_pr', align: 'left', groupable:false},
                { text: 'PR ID', value: 'pr_id', align: 'left' },
                // { text: 'Periode', value: 'periode', width:120, align: 'left', groupable:false},
                // { text: 'Raw Flag', value: 'raw_flag', width:120, align: 'left', groupable:false},
                { text: 'Dept Name', value: 'dept_id', align: 'left', groupable:false},
                // { text: 'PIC', value: 'pic_name', width:120, align: 'left', groupable:false},
                { text: 'PR Item', value: 'pr_item', align: 'left', groupable:false},
                { text: 'Product', value: 'product', width:200, align: 'left', groupable:false},
                { text: 'Description', value: 'description', width:200, align: 'left', groupable:false},
                { text: 'Qty', value: 'qty', align: 'right', groupable:false},
                { text: 'Qty PO', value: 'qty_po', align: 'right', groupable:false},
                { text: 'Qty Rcv', value: 'qty_rcv', align: 'right', groupable:false},
                { text: 'Unit Meas', value: 'unit_meas', align: 'left', groupable:false},
                { text: 'Status', value: 'stat', align: 'left', groupable:false},
                // { text: 'Date Apprv 1', value: 'dt_aprv1', width:200, align: 'left', groupable:false},
                // { text: 'Apprv 1 by', value: 'aprv1_by', width:200, align: 'left', groupable:false},
                // { text: 'Date Apprv 2', value: 'dt_aprv2', width:200, align: 'left', groupable:false},
                // { text: 'Apprv 2 by', value: 'aprv2_by', width:200, align: 'left', groupable:false},
            ],
            items: [],
            searchItemTrans: '',
            pr_items: [],

        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true) 
        await this.getDepartment()
        await this.getRegion()
        await this.getOffice()
        await this.getCategory()
        await this.getCommodity()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        async getDepartment() {
            await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getDepartment`,
                {
                    // 'region_id' : this.region_id,
                    // 'descr' : this.descr
                }, 
                {
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                }
            )
            .then(res => {
                this.departments = res.data.data;
            });
        },
        async getRegion() {
            await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getRegion`,
                {
                    'region_id' : this.region_id,
                    'descr' : this.descr
                }, 
                {
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                }
            )
            .then(res => {
                this.regions = res.data.data;
            });
        },
        async getOffice() {
            await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/getOffice`,
                {
                    'office_id' : this.office_id,
                    'office' : this.office
                }, 
                {
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                }
            )
            .then(res => {
                this.offices = res.data.data;
            });
        },
        async getCategory() {
            await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/showCat`,
                {
                    'cat_id' : this.cat_id,
                    'descr' : this.descr
                }, 
                {
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                }
            )
            .then(res => {
                this.categories = res.data.data;
            });
        },
        async getCommodity() {
            await axios.post(`${process.env.VUE_APP_URL}/api/kri/master/showCom`, {
                    'cat_id' : this.cat_id,
                    'com_id' : this.com_id,
                    'com_desc' : this.com_desc,
                }, 
                {
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
                }
            )
            .then(res => {
                this.commodities = res.data.data;
            });
        },
        clear(){
            var dt_detail =  [];
            var container_detail = 'container1';
            var title_detail = ''
            var label_detail = ''
            this.getPieChart(dt_detail, container_detail, title_detail, label_detail, true)
            this.date_from = ''
            this.date_to = ''
            this.raw = ''
            this.department = ''
            this.status = ''
            this.region_id = ''
            this.office_id = ''
            this.cat_id = ''
            this.com_id = ''
            this.items = []
            this.showData = true
        },
        async searchFunction(){
            this.$store.dispatch('setOverlay', true) 

            var reqBody = {
                'startPeriod': this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'endPeriod': this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : '',
                // 'raw_flag': this.raw ? this.raw : '',
                'region_id': this.region_id ? this.region_id : '',
                'office_id': this.office_id ? this.office_id : '',
                'cat_id': this.cat_id ? this.cat_id : '',
                'com_id': this.com_id ? this.com_id : '',
                'dept_id': this.department ? this.department : '',
                'stat': this.status ? this.status : ''
            }

            const respData = await backendApi.fetchCore('/api/kri/purchase_request/prDetail', reqBody, false, false, false)
            const respDataChart = await backendApi.fetchCore('/api/kri/purchase_request/prChart', reqBody, false, false, false)

            if (respData.status === 200 && respDataChart.status === 200) {
                console.log(respData.data);
                console.log(respDataChart.data);
                this.showData = false
                this.items = respData.data
                var dt_detail =  [];
                var label_detail = "Periode: "+this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''+ '-' + this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : '';
                for (var i = 0; i < respDataChart.data.length; i++) {
                    dt_detail.push({  label: respDataChart.data[i].stat,  y: parseInt(respDataChart.data[i].total_status), legendText: respDataChart.data[i].stat });
                }
                var container_detail = 'container1';
                var title_detail = 'by Status'
                this.getPieChart(dt_detail, container_detail, title_detail, label_detail, false)
                this.$store.dispatch('setOverlay', false)  
            }

        },
        async exportExcel(){
            var reqBody = {
                'startPeriod': this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '',
                'endPeriod': this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : '',
                // 'raw_flag': this.raw ? this.raw : '',
                'region_id': this.region_id ? this.region_id : '',
                'office_id': this.office_id ? this.office_id : '',
                'cat_id': this.cat_id ? this.cat_id : '',
                'com_id': this.com_id ? this.com_id : '',
                'dept_id': this.department ? this.department : '',
                'stat': this.status ? this.status : ''
            }
            const respData = await backendApi.fetchCore('/api/kri/purchase_request/prDetail', reqBody, false, false, false)

            if (respData.status === 200){
                this.pr_items = respData.data
                return this.pr_items
            }
        },
        startDownload(){this.$store.dispatch('setOverlay', true)},
        finishDownload(){this.$store.dispatch('setOverlay', false)},
        getPieChart(dt_detail, container_detail, title_detail, label_detail, destroy = false){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (destroy == true) {
                pie_chart.destroy();
            } else {
                if (x.matches) {

                    for(var i = 0; i < pie_chart.options.data.length; i++){
                        pie_chart.options.data[i].indexLabelFontSize = 6;
                    }
                    pie_chart.render();
                }

                pie_chart.render();
            }

            
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
    },    
}
</script>